<!--
 * @Descripttion:标准修订数据统计 NormDataModifyStatistics 
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-11 14:35:10
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-03-29 23:21:00
-->
<template>
   <div class="NormDataModifyStatistics">
      <div class="NormDataModifyStatisticsHeader">
         <Form ref="formInline" label-position="right" :label-width="80" inline>
            <QueryParam ref="QueryParam" />
            <FormItem>
               <Button type="primary" class="leftBtn" @click="query">查询</Button>
               <Button class="leftBtn" @click="reset">重置</Button>
            </FormItem>
         </Form>
      </div>
      <div class="NormDataModifyStatisticsContent">  
         <QueryResult ref="QueryResult" :columns="columns" />
      </div>
   </div>
</template>
<script>
import QueryResult from '@/components/QueryResult';
import QueryParam from '@/components/QueryParam';
import NormDataModifyStatistics from '@/api/NormDataModifyStatistics';
import commonApi from '@/api/commonApi';
export default {
   name:"NormBaseQuery",
   data(){
      return {
         columns:[
            {"title":"序号","key":"indexNo","width":65},
            {"title":"标准号","key":"standNo","width":100
              //  "render":(h, params) => {
              //     var value = params["row"]["standNo"]
              //     return h('div', [
              //        h('a', {
              //              on: {
              //                 click: () => {
              //                    this.$refs["statisticsModal"].open({
              //                       "standNo":value
              //                    });
              //                 }
              //              }
              //           }, value)
              //        ]);
              //  }
            },
            {"title":"标准名称","key":"standName",
               "width":150
              //  "render":(h, params) => {
              //     let value = params["row"]["standName"];
              //     let fjUrl = params["row"]["fjUrl"];
              //     return h('div', [
              //        h('a', {
              //              on: {
              //                 click: () => {
              //                    this.$refs["PdfModal"].open(fjUrl);
              //                 }
              //              }
              //           }, value)
              //        ]);
              //  }
            },
            {"title":"修订类别","key":"reviseType","width":100},
            {"title":"标准原文","key":"otherInfo"},
            {"title":"修订意见","key":"reviesCont"},
            {"title":"备注","key":"reviesDesc","width":150},
            {"title":"提出人单位","key":"reviesCor","width":120},
            {"title":"提出人姓名","key":"reviseUser","width":120},
            {"title":"提出日期","key":"reviesTime","width":100},
            {"title":"操作",
            "width":65,
               "render":(h, params) => {
                  let bz05Id = params['row']['bz05Id']
                  return h('div', [
                     h('a', {
                           on: {
                              click: () => {
                                 this.deleteRecords(bz05Id) 
                              }
                           }
                        }, '删除')
                     ]);
               }
            }
         ]
      }
   },
   components:{
      "QueryResult":QueryResult,
      "QueryParam":QueryParam
   },
   mounted(){
      // 获取查询参数;
      if(this.$route.params.standName){
         let {standName} = this.$route.params;
         this.$refs["QueryParam"].setParam({standName})
      }
      if(this.$route.params.standNo){
        let {standNo} = this.$route.params;
        this.$refs["QueryParam"].setParam({standNo})
      }
      this.query();
   },
   methods:{
      query(){
          let queryParam = this.$refs["QueryParam"].getParam();
          this.$refs["QueryResult"].query(NormDataModifyStatistics.query,queryParam,true);
      },
      deleteRecords(id){
         commonApi.delete({"id":id}).then((resp)=>{
               if(resp && resp["code"] == 1000){
                    this.query();
               }else{
                   this.$message.info(resp["message"]);
               }
           })
      },
      formatterVal(params,key){
         let result = JSON.parse(sessionStorage.getItem("DD003"));
         let row = params['row'];
         return result.filter((item)=>{return item["statusNo"] == row[key]})[0]["statusDesc"];
      },
      reset(){
         this.$refs["QueryParam"].reset();
      }
   }
}
</script>
<style lang="less">
.NormDataModifyStatisticsHeader{
   height:58px;
   .ivu-form-item{
      float:left;
   }
   .leftBtn{
      margin-right:20px;
   }
}

</style>